import axios from 'axios'
import swal from 'sweetalert'
import AOS from 'aos'
import * as Sentry from "@sentry/browser";

const headerDom = document.querySelector('header')

// FORM
const formDom = document.getElementById('formDom')
const formInputDownload = document.querySelectorAll('.form__input-download')
const buttonDownload = document.getElementById('btnDownload')
const formInputFileDummy = document.getElementById('fileDummy')
const fileUpload = document.getElementById('document')
const documentAgreementCheck = document.querySelectorAll('.document-agreement-check')
const buttonSubmit = document.getElementById('btnSubmit')

function headerScroll() {
  const wScroll = window.scrollY
  if (wScroll > 40) {
    headerDom.classList.add('header--scroll')
  } else {
    headerDom.classList.remove('header--scroll')
  }
}

headerScroll()

window.addEventListener('scroll', headerScroll)

AOS.init({
  duration: 400,
  easing: 'ease',
  startEvent: 'DOMContentLoaded',
  once: true
})

window.onload = () => {
  // contentDom.style.marginTop = `${headerDom.offsetHeight}px`
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault()
    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    })
  })
})


const serialize = (formEl) => {
  const formData = [];
  for (let i = 0; i < formEl.elements.length; i++) {
    const field = formEl.elements[i];
    if (field.name && !field.disabled && field.type !== 'button' && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit') {
      if (field.type === 'checkbox' && !field.checked) {
        continue
      }
      formData[formData.length] = `${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`;
    }
  }
  return formData.join('&').replace(/%20/g, '+');
};

function isEmailValid(email){
  const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return reg.test(email)
}

/*  eslint func-names: ["off"] */
/*  eslint prefer-arrow-callback: ["off"] */
formDom.addEventListener('submit', function (e) {
  e.preventDefault()
  const btnSubmit = this.querySelector('[type="submit"]');
  const btnText = btnSubmit.textContent;
  const formData = new FormData();
  btnSubmit.setAttribute('disabled', 'true');
  btnSubmit.textContent = 'Submitting...';

  formData.append('name', document.getElementById('name').value)
  formData.append('company_name', document.getElementById('company_name').value)
  formData.append('position', document.getElementById('position').value)
  formData.append('city', document.getElementById('city').value)
  formData.append('phone', document.getElementById('phone').value)
  formData.append('email', document.getElementById('email').value)
  formData.append('program', document.getElementById('program').value)
  for (let i = 0; i < fileUpload.files.length; i++) {
    formData.append('document_path[]', fileUpload.files[i])
  }
  formData.append('g-recaptcha-response', grecaptcha.getResponse())
  
  axios.post('https://lenovodcg-redeem.com/form_multiple.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => {
      if (res.data.success) {
        swal('Terima kasih', 'Data sukses terkirim', 'success');
        this.reset()
        grecaptcha.reset();
        btnSubmit.removeAttribute('disabled')
        btnSubmit.textContent = btnText;
        // buttonDownload.setAttribute('disabled', true)
      } else {
        swal('Opps', res.data.message, 'error');
      }
    })
    .catch((err) => {
      // swal('Opps', 'We are under maintenance', 'error');
      formData.append('error', err)
      axios.post('https://lenovodcg-redeem.com/error_email.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((res) => {
          console.log(res)
        })

      Sentry.captureMessage({
        error: {
          status: err.response.status,
          response: JSON.stringify(err.response.data),
          header: JSON.stringify(err.response.headers)
        },
        payload: {
          name: document.getElementById('name'),
          company: document.getElementById('company_name'),
          position: document.getElementById('position'),
          city: document.getElementById('city'),
          phone:  document.getElementById('phone'),
          email:  document.getElementById('email'),
          program: document.getElementById('program')
        }
      });
    })
    .finally(() => {
      btnSubmit.removeAttribute('disabled')
      btnSubmit.textContent = btnText;
    });
})

function setInputFilter(textbox, inputFilter) {
  ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      /*  eslint no-prototype-builtins: ["off"] */
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      }
    });
  });
}

document.querySelectorAll('.input__number-only').forEach((input) => {
  setInputFilter(input, function (value) {
    return /^\d*\.?\d*$/.test(value);
  });
});

formInputDownload.forEach((input) => {
  input.addEventListener('input', function() {
    // if (isFormDownloadEnable()) {
    //   buttonDownload.removeAttribute('disabled')
    // } else {
    //   buttonDownload.setAttribute('disabled', true)
    // }
    isFormSubmitEnable()
  })
})

fileUpload.addEventListener('change', function() {
  const fileObj = this.files[0]
  formInputFileDummy.value = fileObj.name
  isFormSubmitEnable()
})

documentAgreementCheck.forEach((check) => {
  check.addEventListener('change', function() {
    isFormSubmitEnable()
  })
})

function isFormUploadEnable() {
  let isButtonUploadEnable = true
  documentAgreementCheck.forEach((input) => {
    if (!input.checked) {
      isButtonUploadEnable = false
    }
  })
  if (formInputFileDummy.value === "") {
    isButtonUploadEnable = false
  }
  return isButtonUploadEnable
}

function isFormDownloadEnable() {
  let isButtonDownloadEnable = true
  formInputDownload.forEach((input) => {
    if (input.value === "") {
      isButtonDownloadEnable = false
    }
  })
  return isButtonDownloadEnable
}

function isFormSubmitEnable() {
  if (isFormDownloadEnable() && isFormUploadEnable()) {
    buttonSubmit.removeAttribute('disabled')
  } else {
    buttonSubmit.setAttribute('disabled', true)
  }
}

function download(url, name) {
  const shadowLinkTag = document.createElement('a');
  shadowLinkTag.href = url;
  shadowLinkTag.download = name;
  shadowLinkTag.click();
}

buttonDownload.addEventListener('click', () => {
  download('https://lenovodcg-redeem.com/assets/Form-Claim-End-User-Promo-2020_NOV.docx', 'Form-Claim-End-User-Promo-2020_NOV.docx')
})