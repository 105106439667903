import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

import './js/main.js'

Sentry.init({
  dsn: "https://d598c2a1ff2f4c0f9edf6d0046447b59@o533445.ingest.sentry.io/5748211",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

import './scss/main.scss'